<template>
  <div>
    <div>
      <b-form>
        <template v-if="company && displayJurisdictionSelection">
          <p>
            <span>
              Your company's home state {{ filingTenseDisplayed }} <strong>{{ jurisdictionDisplayed }}</strong>.
            </span>
            <a href="#" aria-label="change here link" @click="changeHomeStateHandler">
              <small>Click here to change</small>
            </a>
          </p>
        </template>

        <b-form-group v-if="jurisdictions.length > 1 || isFilingDba && allowFilingJurisdictionSelection">
          <template slot="label">
            Where would you like to make your <strong>{{ humanizedFilingName }}</strong> filing?
          </template>
          <multi-select
            v-model="jurisdictionSelection"
            :multiple="isMultiJurisdiction"
            :placeholder="`Choose where to file ${humanizedFilingName}`"
            :options="jurisdictions"
            @select="filingJurisdictionSelected"
            @remove="removeByJurisdiction"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option }}
            </template>
          </multi-select>

          <b-card v-if="isFilingDba && showJurisdictionDisclaimer" class="mt-3 jurisdiction-disclaimer">
            <b-card-text>
              <em>
                <small>
                  <b>{{ jurisdictionDisclaimer }}</b>
                </small>
              </em>
            </b-card-text>
          </b-card>

          <div v-if="localOptions.length && isFilingDba" class="mt-3">
            <multi-select
              v-model="localJurisdictionSelection"
              :multiple="true"
              :placeholder="`Choose the cities / counties to file ${humanizedFilingName}`"
              :options="localOptions"
              group-values="local"
              group-label="state"
              track-by="name"
              label="name"
              :group-select="false"
              @select="filingLocalJurisdictionSelected"
              @remove="removeByLocalJurisdiction"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option }}
              </template>
            </multi-select>

            <small>* If your city/county is not listed in the dropdown above, please email us so we can determine if it can be added and facilitate the filing process for you.</small>
          </div>
        </b-form-group>
      </b-form>

      <div v-if="ineligibleToOrderProduct(product) && !loading" class="ineligible-product">
        <p class="ineligible-product-inner">
          <span>
            {{ ineligibleToOrderText }}
          </span>
        </p>
        <b-button
          variant="primary"
          style="min-width: 130px; float: right"
          aria-label="back button"
          @click="closeModal()"
        >
          Back
        </b-button>
      </div>
      <div v-else>
        <b-list-group>
          <b-list-group-item
            v-for="(jurisdiction, index) in iJurisdictionSelection"
            :key="index"
            class="flex-column align-items-start"
          >
            <div class="d-flex w-100 justify-content-between">
              <h3 class="mb-1">
                {{ jurisdiction }} &mdash; {{ product.name }}
              </h3>
            </div>


            <div v-if="hasFilingInCart && nonHireUsRoute" class="mt-4 mb-4">
              <p>
                Looks like you already have a Formation Filing in your cart.
              </p>
              <p>
                Remove the Formation Filing from the cart in order to select a different one.
              </p>
            </div>

            <div v-else-if="hasActiveFiling" class="mt-4 mb-4">
              <p>
                There is already an active Formation Filing on your account.
              </p>
            </div>

            <div v-else>
              <div v-if="!isLocal[jurisdiction] && !(isVehicleRegistration && ourFee(jurisdiction) <= 0)">
                <small>
                  {{ ourFee(jurisdiction) | dollars }} - Our fee
                </small><br>
              </div>

              <div v-if="dbaAddOns[jurisdiction]?.publicationCost > 0">
                <small>{{ dbaAddOns[jurisdiction].publicationCost | dollars }} - {{ dbaAddOns[jurisdiction].methodName }} Publication Filing</small>
              </div>

              <div v-if="dbaAddOns[jurisdiction]?.cogsCost > 0">
                <small>{{ dbaAddOns[jurisdiction].cogsCost | dollars }} - {{ dbaAddOns[jurisdiction].methodName }} Certificate Of Good Standing Filing</small>
              </div>

              <div v-if="freeFilingProcessingFee(jurisdiction)">
                <small> {{ freeFilingProcessingFee(jurisdiction) | dollars }} - Credit Card Processing Fee</small>
              </div>

              <div v-if="raCost(product, jurisdiction)">
                <small>{{ raCost(product, jurisdiction) | dollars }} - Registered agent service</small>
              </div>

              <div v-if="shouldIncludeRenewalService(product, jurisdiction)">
                <small>${{ renewalCost() }} - Renewal Service</small>
              </div>

              <div v-if="boiEnabled">
                <small>${{ boiCost() }} - Beneficial Ownership Information Report</small>
              </div>

              <div v-if="isLocal[jurisdiction] && isFilingDba">
                <div class="mb-3 border-bottom pt-3 pb-3">
                  <div v-for="localJurisdiction in iLocalJurisdictionSelected" :key="localJurisdiction.name">
                    <div v-if="localJurisdiction.state === jurisdiction">
                      <div class="font-weight-bold">
                        {{ localJurisdiction.name }}
                      </div>
                      <div v-if="isLocal[jurisdiction]">
                        <small>{{ ourFee(localJurisdiction) | dollars }} - Our fee</small>
                      </div>

                      <div v-if="dbaAddOns[localJurisdiction.name]?.publicationCost > 0">
                        <small>{{ dbaAddOns[localJurisdiction.name].publicationCost | dollars }} - {{ dbaAddOns[localJurisdiction.name].methodName }} Publication Filing</small>
                      </div>

                      <div v-if="dbaAddOns[localJurisdiction.name]?.cogsCost > 0">
                        <small>{{ dbaAddOns[localJurisdiction.name].cogsCost | dollars }} - {{ dbaAddOns[localJurisdiction.name].methodName }} Certificate Of Good Standing Filing</small>
                      </div>

                      <b-form-radio
                        v-for="method in filingMethodsForLocalJurisdiction(localJurisdiction)"
                        :key="method.id"
                        v-model="filingMethodSelection[localJurisdiction.name]"
                        :name="localJurisdiction.name"
                        :value="method"
                        :disabled="localAddedToSaved(localJurisdiction.name)"
                        class="ml-3"
                      >
                        <div>
                          {{ method.cost | dollars }} &mdash; {{ method.name }} {{ method.filing_description }}
                        </div>
                        <small>{{ method | filingMethodTimingNoticeText }}</small>
                      </b-form-radio>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="mb-3 border-bottom pt-3 pb-3">
                <b-checkbox
                  v-if="monitoringServiceIsAvailable"
                  v-model="monitoringServiceSelected"
                  @change="monitoringProductSelected"
                >
                  {{ monitoringProductPricingInfo() }}
                  <fa-icon
                    v-b-popover.hover.top="toolTipDataForMonitoring"
                    class="question-circle pl-1"
                    icon="question-circle"
                    size="xs"
                  />
                </b-checkbox>
                <b-form-radio
                  v-for="method in filingMethodsForJurisdiction(jurisdiction)"
                  :key="method.id"
                  v-model="filingMethodSelection[jurisdiction]"
                  :name="jurisdiction"
                  :value="method"
                  :disabled="addedToSaved(jurisdiction)"
                >
                  <div>
                    {{ method.cost | dollars }} &mdash; {{ method.name }} {{ method.filing_description }}
                  </div>

                  <small>{{ method | filingMethodTimingNoticeText }}</small>
                </b-form-radio>
              </div>

              <div class="lower-container" :class="{ 'lower-container-single-item': !containsJurisdiction(jurisdiction) }">
                <div v-if="containsJurisdiction(jurisdiction)" class="lower-container-text">
                  Total cost for {{ jurisdiction }} {{ product.filing_name }} filing:
                  {{ totalCost(product, jurisdiction) | dollars }}
                </div>

                <div v-if="isLocal[jurisdiction]" class="lower-container-btn">
                  <b-button
                    v-if="groupAddedToSave(jurisdiction)"
                    variant="danger"
                    class="mr-1"
                    @click="removeById(jurisdiction)"
                  >
                    Remove All
                  </b-button>

                  <b-button
                    v-if="!addedToSaved(jurisdiction)"
                    variant="primary"
                    :disabled="!containsJurisdiction(jurisdiction) || isStateLoading(loading, jurisdiction)"
                    style="min-width: 130px"
                    :aria-label="submissionLabel + ' button'"
                    @click="submitDecision(product, jurisdiction)"
                  >
                    <div v-if="!isStateLoading(loading, jurisdiction, index)">
                      {{ submissionLabel }}
                    </div>
                    <b-spinner v-else small variant="light" />
                  </b-button>
                </div>

                <div v-else class="lower-container-btn">
                  <b-button
                    v-if="addedToSaved(jurisdiction)"
                    variant="danger"
                    @click="removeById(jurisdiction)"
                  >
                    Remove
                  </b-button>

                  <b-button
                    v-else
                    variant="primary"
                    :disabled="!containsJurisdiction(jurisdiction) || isStateLoading(loading, jurisdiction)"
                    style="min-width: 130px"
                    @click="submitDecision(product, jurisdiction)"
                  >
                    <div v-if="!isStateLoading(loading, jurisdiction, index)">
                      {{ submissionLabel }}
                    </div>
                    <b-spinner v-else small variant="light" />
                  </b-button>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <!--Although this also appears automatically in other Hire Us components, it is here for
          manually updating their jurisdiction-->
      <company-domestic-registration-modal
        ref="company-domestic-registration-modal"
        :company="company"
        :load-products="false"
        @domestic-registration-changed="domesticRegistrationChanged"
      />
    </div>
  </div>
</template>

<script>
  /*

  # Filing Options
  Let the user decide between different filing options for a product.

  ## Design Decisions
  This component has been intentionally decoupled from the Vuex store to allow reuse in different
  contexts. Filing options are are displayed for a single product, or for multiple products in a bundle.
  When modifying this component please note that both Bundles and Filing Services are affected.

  This is the presentational component in the Presentational/Container Component design pattern:
  https://medium.com/@dan_abramov/smart-and-dumb-components-7ca2f9a7c7d0

  ## Events
  @remove-for-jurisdiction
  @remove-by-id
  @submit

  */

  import MultiSelect from 'vue-multiselect'
  import { sortBy } from 'lodash/collection'
  import { uniq } from 'lodash/array'
  import { dollars, filingMethodTimingNoticeText } from '@/filters'
  import { humanize } from '@/common/modules/strings'
  import { mapGetters, mapActions } from 'vuex'
  import {
    MULTI_JURISDICTION_FILINGS,
    FILINGS_PACKAGED_WITH_RA_SERVICE,
    FILINGS_PACKAGED_WITH_RENEWAL_SERVICE,
  } from '@/common/modules/constants'
  import { dbaJurisdictionDisclaimers } from '@/common/modules/jurisdictionDisclaimers'

  export default {
    name: 'FilingOptions',

    filters: {
      dollars,
      filingMethodTimingNoticeText,
    },

    components: {
      CompanyDomesticRegistrationModal: () => import('@/components/CompanyDomesticRegistrationModal'),
      MultiSelect,
    },

    props: {
      product: Object,
      // eslint-disable-next-line vue/require-prop-type-constructor
      bundleBasePrice: 0,
      company: Object,
      loading: Boolean,
      savedItems: [Object, Array],
      submissionLabel: String,
      isDisplayingBundle: {
        type: Boolean,
        default: false,
      },
      disableDomesticForm: {
        type: Boolean,
        default: false,
      },
      allowMultiJurisdiction: {
        type: Boolean,
        default: true,
      },
      disableRegisteredAgentAddon: {
        type: Boolean,
        default: false,
      },
      monitoringProduct: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        iJurisdictionSelection: [],
        localOptions: [],
        iLocalJurisdictionSelected: [],
        isLocal: {},
        filingMethodSelection: {},
        localFilingMethods: {},
        showHomeStateFormFilings: ['form a company', 'amendment', 'change registered agent'],
        ineligibleToOrderText: '',
        monitoringServiceSelected: true, // Default to true
        monitoringToolTip: "Trademark Monitoring identifies threats to your mark, summing up " +
          "findings in streamlined, monthly reports. Let us focus on your brand security so you " +
          "can focus on everything else. Free for 90 days, then $14 per month.",
        jurisdictionDisclaimers: dbaJurisdictionDisclaimers,
        boiEnabled: false,
        dbaAddOns: {},
      }
    },
    computed: {
      ...mapGetters('checkout', [
        'registeredAgentProductByJurisdiction',
        'cartItems',
        'renewalService',
        'boiFiling',
        'formationProductInCart',
        'raServiceActiveOrInCart',
        'formationProductInCart',
      ]),
      ...mapGetters('stageline', ['isStagelineRoute']),
      ...mapGetters('companies', [
        'hasExistingRAServiceInJurisdiction',
        'domesticRegistration',
        'checkoutDomesticJurisdiction',
        'formationFiling',
      ]),
      ...mapGetters('jurisdictions', ['findByName']),
      ...mapGetters('orderItems', [
        'eligibleToOrderEin',
        'eligibleToOrderSCorp',
        'requiresFormationFiling',
      ]),
      ...mapGetters('stageline', ['isFormationFiling']),
      ...mapGetters('companies', [
        'formationFiling',
        'currentCompany',
      ]),
      ...mapGetters('monitoring', [
        'monitoringTrialEligible',
        'monitoringServiceActive',
      ]),

      isTrademarkProduct() {
        return this.product.filing_name === "trademark"
      },

      displayMonitoringService() {
        return this.isStagelineRoute && !this.monitoringServiceActive && this.isTrademarkProduct
      },

      monitoringServiceIsAvailable() {
        return this.displayMonitoringService && this.monitoringProduct?.show_to_client
      },

      jurisdictions() {
        if (!this.product.filing_methods) {
          return []
        }

        let jurs = sortBy(uniq(this.product.filing_methods.map(fm => fm.jurisdiction)))
        if (this.product.filing_name === 'register a company') {
          jurs = jurs.filter(jur => {
            return ![this.domesticRegistration?.jurisdiction?.state_province_region,
              this.checkoutDomesticJurisdiction?.state_province_region].includes(jur)
          })
        }

        return jurs
      },

      isMultiJurisdiction() {
        return this.allowMultiJurisdiction && MULTI_JURISDICTION_FILINGS.includes(this.product.filing_name)
      },

      showJurisdictionDisclaimer() {
        return this.jurisdictionSelection.some(state => this.jurisdictionDisclaimers[state])
      },
      jurisdictionDisclaimer() {
        const selectedDisclaimers = []

        this.jurisdictionSelection.forEach((jurisdiction) => {
          if (
            this.jurisdictionDisclaimers[jurisdiction] &&
            !selectedDisclaimers.includes(jurisdiction)
          ) {
            selectedDisclaimers.push(this.jurisdictionDisclaimers[jurisdiction])
          }
        })

        return selectedDisclaimers.join('\n\n')
      },

      isFilingDba() {
        return this.product.filing_name === 'assumed name' || this.product.filing_name === 'trade name'
      },

      existingSavedItems() {
        return this.savedItems.filter(i => i.product_id === this.product.id)
      },

      jurisdictionSelection: {
        get() {
          if (this.isMultiJurisdiction) {
            return this.iJurisdictionSelection
          }

          return this.iJurisdictionSelection[0]
        },

        set(val) {
          if (this.isMultiJurisdiction) {
            this.iJurisdictionSelection = val
          } else {
            this.iJurisdictionSelection = val ? [val] : []
          }
        },
      },
      localJurisdictionSelection: {
        get() {
          return this.iLocalJurisdictionSelected
        },

        set(val) {
          this.iLocalJurisdictionSelected = val
        },
      },
      displayJurisdictionSelection() {
        return !this.isDisplayingBundle
          && !this.disableDomesticForm
          && this.showHomeStateFormFilings.includes(this.product.filing_name)
      },
      filingTenseDisplayed() {
        if (this.domesticRegistration) {
          return 'is'
        } else if (this.checkoutDomesticJurisdiction) {
          return 'will be'
        } else {
          return 'is'
        }
      },
      jurisdictionDisplayed() {
        return this?.domesticRegistration?.jurisdiction?.state_province_region
          || this?.checkoutDomesticJurisdiction?.state_province_region
      },
      humanizedFilingName() {
        return humanize(this.product.filing_name)
      },
      allowFilingJurisdictionSelection() {
        return this.product.filing_name !== 'form a company'
      },
      formationProduct() {
        return ['form a company', 'free form a company'].includes(this.product.filing_name)
      },
      hasFilingInCart() {
        return this.formationProduct && this.formationProductInCart
      },
      hasActiveFiling() {
        return this.formationProduct && this.formationFiling
      },
      nonHireUsRoute() {
        const path = this.$route.path
        return !path.includes('hire-us')
      },
      isVehicleRegistration () {
        const path = this.$route.path
        return path.includes('vehicle-registrations')
      },
    },
    watch: {
      domesticRegistration(newReg, _oldReg) {
        this.updateFilingMethodsForJurisdiction(newReg.jurisdiction.state_province_region)
      },
      checkoutDomesticJurisdiction(newJur, _oldJur) {
        this.updateFilingMethodsForJurisdiction(newJur.state_province_region)
      },
    },

    async mounted() {
      this.boiEnabled = await this.enableBoiAddOn(this.product)

      if (!this.monitoringProduct?.show_to_client) {
        this.monitoringServiceSelected = false
      }
      if (
        (this.product.filing_methods && this.product.filing_methods.length === 1) ||
        uniq(this.product.filing_methods.map(fm => fm.jurisdiction)).length === 1
      ) {
        if (this.isFilingDba) {
          const jurisdiction = this.product.filing_methods[0].jurisdiction
          this.iJurisdictionSelection = [jurisdiction]
          this.filingJurisdictionSelected(jurisdiction)
        }
        else {
          const jurisdiction = this.product.filing_methods[0].jurisdiction
          this.iJurisdictionSelection = [jurisdiction]
        }
      } else if (this.isFilingDba) {
        const homeJurisdiction = this.domesticRegistration?.jurisdiction?.state_province_region
        this.iJurisdictionSelection = [homeJurisdiction]
        this.filingJurisdictionSelected(homeJurisdiction)
      }
      else if (this.product.filing_name === 'form a company') {
        let jurisdiction = this.domesticRegistration?.jurisdiction?.state_province_region
          || this.checkoutDomesticJurisdiction?.state_province_region
        this.updateFilingMethodsForJurisdiction(jurisdiction)
      }

      this.existingSavedItems.forEach(item => {
        const method = this.product.filing_methods.find(fm => fm.id === item.filing_method_id) || item?.filing_method
        const jurisdiction = method?.jurisdiction
        if (!jurisdiction) return

        if (method && !this.iJurisdictionSelection.includes(jurisdiction)) {
          this.iJurisdictionSelection.push(jurisdiction)
          this.filingJurisdictionSelected(jurisdiction)

          if (this.isLocal[jurisdiction]) {
            this.iLocalJurisdictionSelected.push({ name: method.local_jurisdiction, state: jurisdiction })
            this.filingMethodSelection[method.local_jurisdiction] = method
          } else {
            this.filingMethodSelection[jurisdiction] = method
          }
        } else if (method && this.isLocal[jurisdiction]) {
          this.iLocalJurisdictionSelected.push({ name: method.local_jurisdiction, state: jurisdiction })
          this.filingMethodSelection[method.local_jurisdiction] = method
        } else if (method && this.iJurisdictionSelection.includes(jurisdiction)) {
          this.filingJurisdictionSelected(jurisdiction)
          if (!this.isLocal[jurisdiction]) this.filingMethodSelection[jurisdiction] = method
        }
        if (this.isFilingDba) {
          this.dbaAddOnCosts(method, method.local_jurisdiction)
        }
      })
    },

    methods: {
      ...mapActions('checkout', [
        'enableBoiAddOn',
        'clearCart',
        'fetchDbaAddOns',
      ]),
      isStateLoading(loading, jurisdiction) {
        return this.jurisdictionLastSaved === jurisdiction && loading
      },
      containsJurisdiction(jurisdiction) {
        let doesContain = []
        let localArray = []

        if (this.filingMethodSelection[jurisdiction] && !this.isLocal[jurisdiction]) {
          doesContain.push(true)
        }
        else if (this.isLocal[jurisdiction] && this.iLocalJurisdictionSelected.length) {
          this.iLocalJurisdictionSelected.forEach(selection => {
            if (selection.state === jurisdiction) {
              if (this.filingMethodSelection[selection.name]) {
                localArray.push(selection.name)
                doesContain.push(true)
              }
              else {
                doesContain.push(false)
              }
            }
          })

          if (localArray.length === 0) {
            doesContain.push(false)
          }

          this.localFilingMethods[jurisdiction] = localArray
        }
        else {
          doesContain.push(false)
        }

        return !doesContain.some(val => val === false)
      },
      monitoringProductPricingInfo() {
        if (this.monitoringTrialEligible) {
          return `${this.monitoringProduct.name} - Free Trial (${this.monitoringProduct.trial_duration_days}) days`
        }

        return `${this.monitoringProduct.name} ${this.monitoringProduct.price}`
      },
      toolTipDataForMonitoring() {
        return `Stay ahead of trademark infringement with Trademark Monitoring. Get automatic, continuous monitoring with custom reports sent to your inbox every month.`
      },
      submitDecision(product, jurisdiction) {
        this.jurisdictionLastSaved = jurisdiction
        product.totalCost = this.totalCost(product, jurisdiction)

        if (this.localFilingMethods[jurisdiction]){
          const nameArray = this.localFilingMethods[jurisdiction]

          nameArray.forEach(name => {
            if (!this.localAddedToSaved(name)) {
              const filingInformation = {
                product,
                jurisdiction,
                filingMethod: this.filingMethodSelection[name],
                filingCost: this.filingCost(product, jurisdiction),
              }

              this.$emit('submit', filingInformation)
            }
          })
        }
        else {
          const filingInformation = {
            product,
            jurisdiction,
            filingMethod: this.filingMethodSelection[jurisdiction],
            filingCost: this.filingCost(product, jurisdiction),
          }

          this.$emit('submit', filingInformation)
        }
      },
      monitoringProductSelected(isSelected) {
        this.monitoringServiceSelected = isSelected
        this.$emit('monitoringProductAdded', this.monitoringServiceSelected)
      },
      totalCost(product, jurisdiction) {
        return this.filingCost(product, jurisdiction) + this.raCost(product, jurisdiction) + this.freeFilingProcessingFee(jurisdiction) + this.boiCost() + this.totalDbaAddOnCosts(jurisdiction)
      },

      filingCost(product, jurisdiction) {
        let ourFee = 0
        let filingCost = 0

        if (this.filingMethodSelection[jurisdiction]) {
          filingCost = this.filingMethodSelection[jurisdiction].cost
          ourFee = this.ourFee(jurisdiction)
        }
        else {
          const stateArray = this.localFilingMethods[jurisdiction]
          stateArray.forEach(name => {
            filingCost += this.filingMethodSelection[name].cost
            ourFee += this.ourFee(name)
          })
        }

        return ourFee + filingCost
      },

      raCost(product, jurisdiction) {
        if (this.isDisplayingBundle || this.disableRegisteredAgentAddon) {
          return 0
        }

        let raCost = 0
        let fullJurisdiction = this.findByName(jurisdiction)
        if (FILINGS_PACKAGED_WITH_RA_SERVICE.includes(product.filing_name) &&
          !this.hasExistingRAServiceInJurisdiction(fullJurisdiction) && !this.raServiceActiveOrInCart) {
          raCost =  this.currentCompany.entity_type !== 'Subsidiary Series LLC'? this.registeredAgentProductByJurisdiction(jurisdiction).price: 0
        }

        return raCost
      },

      shouldIncludeRenewalService(product, jurisdiction) {
        return FILINGS_PACKAGED_WITH_RENEWAL_SERVICE.includes(product.filing_name)
          && this.accountType !== 'wholesale-registered-agent'
          && !this.hasExistingRAServiceInJurisdiction(this.findByName(jurisdiction))
          && this.currentCompany.entity_type !== 'Subsidiary Series LLC'
      },

      renewalCost(){
        return this.renewalService ? this.renewalService.price : 0
      },

      boiCost(){
        return this.boiEnabled ? this.boiFiling.price : 0
      },

      totalDbaAddOnCosts(jurisdiction) {
        let stateArray = this.localFilingMethods[jurisdiction]

        if (!stateArray) {
          stateArray = [jurisdiction]
        }

        let publicationCost = 0
        let cogsCost = 0

        stateArray.forEach(name => {
          if (this.dbaAddOns[name]) {
            publicationCost += this.dbaAddOns[name].publicationCost || 0
            cogsCost += this.dbaAddOns[name].cogsCost || 0
          }
        })
        return publicationCost + cogsCost
      },

      async dbaAddOnCosts(method, jurisdiction) {
        const dependentFilings = await this.fetchDbaAddOns(method)

        if (!this.dbaAddOns[jurisdiction]) {
          this.$set(this.dbaAddOns, jurisdiction, {})
        }

        for (const key in dependentFilings) {
          this.dbaAddOns[jurisdiction].methodName = dependentFilings[key].filing_method_name
          if (dependentFilings[key].product_name === 'publication') {
            this.dbaAddOns[jurisdiction].publicationCost = dependentFilings[key].product_total_cost
          }
          if (dependentFilings[key].product_name === 'certificate of good standing') {
            this.dbaAddOns[jurisdiction].cogsCost = dependentFilings[key].product_total_cost
          }
        }
      },

      ourFee(jurisdiction) {
        if (this.isDisplayingBundle) {
          return this.bundleBasePrice
        }

        if (jurisdiction.state) {
          if (this.isLocal[jurisdiction.state]) {
            const filingMethod = this.product.filing_methods.find(fm => fm.local_jurisdiction === jurisdiction.name)

            const priceOverride = this.product.local_jurisdiction_price_overrides[filingMethod.local_jurisdiction_id]

            return priceOverride >= 0 ? priceOverride : this.product.price
          }
        }
        else if (this.product.filing_methods.find(fm => fm.local_jurisdiction === jurisdiction)){
          const filingMethod = this.product.filing_methods.find(fm => fm.local_jurisdiction === jurisdiction)

          const priceOverride = this.product.local_jurisdiction_price_overrides[filingMethod.local_jurisdiction_id]

          return priceOverride >= 0 ? priceOverride : this.product.price
        }
        else {
          const filingMethod = this.product.filing_methods.find(fm => fm.jurisdiction === jurisdiction)

          const priceOverride = this.product.jurisdiction_price_overrides[filingMethod.jurisdiction_id]

          return priceOverride >= 0 ? priceOverride : this.product.price
        }
      },

      freeFilingProcessingFee(jurisdiction) {
        if (this.product.filing_name !== 'free form a company') {
          return 0
        }
        const sort = (a, b) => { return b.cost - a.cost }
        const filingMethod = this.product.filing_methods.filter(fm => fm.jurisdiction === jurisdiction).sort(sort)[0]

        return Math.round(filingMethod.cost * 0.05)
      },

      removeByJurisdiction(jurisdiction) {
        if (this.isLocal[jurisdiction]) {
          if (this.localFilingMethods[jurisdiction]){
            const nameArray = this.localFilingMethods[jurisdiction]

            nameArray.forEach(name => {
              if (this.localAddedToSaved(name)) {
                const filingMethod = this.filingMethodSelection[name]

                this.$emit(
                  'remove-by-local-jurisdiction',
                  this.jurisdictionSelection,
                  filingMethod
                )
              }
            })
          }

          this.iLocalJurisdictionSelected = this.iLocalJurisdictionSelected.filter(selection => { return selection.state !== jurisdiction })
          this.localOptions = this.localOptions.filter(option => { return option.state !== jurisdiction })
          this.isLocal[jurisdiction] = undefined
        }
        else {
          this.$emit(
            'remove-by-jurisdiction',
            this.jurisdictionSelection,
            this.filingMethodSelection[jurisdiction]
          )
        }
      },

      removeByLocalJurisdiction(jurisdiction) {
        this.$emit(
          'remove-by-local-jurisdiction',
          this.localJurisdictionSelection,
          this.filingMethodSelection[jurisdiction.name]
        )
      },

      removeById(jurisdiction) {
        const savedItems = this.savedItemFor(jurisdiction)

        if (savedItems.length) {
          savedItems.forEach(item => {
            if (item) {
              this.$emit(
                'remove-by-id',
                { id: item.id }
              )
            }
          })
        }
        else {
          this.$emit(
            'remove-by-id',
            { id: savedItems.id }
          )
        }
      },

      groupAddedToSave(jurisdiction) {
        let filingMethodIds = []
        let includesIds = []

        if (this.localFilingMethods[jurisdiction]) {
          const nameArray = this.localFilingMethods[jurisdiction]
          nameArray.forEach(name => {
            filingMethodIds.push(this.filingMethodSelection[name].id)
          })

          filingMethodIds.forEach(id => {
            includesIds.push(this.existingSavedItems.map(i => i.filing_method_id).includes(id))
          })

          if (includesIds.length > 0) {
            return includesIds.some(val => val === true)
          }
        }
      },
      localAddedToSaved(jurisdiction) {
        if (!this.containsJurisdiction(jurisdiction) || !this.existingSavedItems) {
          return false
        }

        return this.existingSavedItems
          .map(i => i.filing_method_id)
          .includes(this.filingMethodSelection[jurisdiction].id)
      },
      addedToSaved(jurisdiction) {
        let filingMethodIds = []
        let includesIds = []

        if (!this.containsJurisdiction(jurisdiction) || !this.existingSavedItems) {
          return false
        }

        if (this.localFilingMethods[jurisdiction]) {
          const nameArray = this.localFilingMethods[jurisdiction]
          nameArray.forEach(name => {
            filingMethodIds.push(this.filingMethodSelection[name].id)
          })

          filingMethodIds.forEach(id => {
            includesIds.push(this.existingSavedItems.map(i => i.filing_method_id).includes(id))
          })

          if (includesIds.length > 0) {
            return !includesIds.some(val => val === false)
          }
        }
        else {
          return this.existingSavedItems
            .map(i => i.filing_method_id)
            .includes(this.filingMethodSelection[jurisdiction].id)
        }
      },

      savedItemFor(jurisdiction) {
        let filingMethods = []

        if (this.localFilingMethods && this.localFilingMethods[jurisdiction]) {
          const nameArray = this.localFilingMethods[jurisdiction]
          nameArray.forEach(name => {
            filingMethods.push(this.existingSavedItems.find(i => i.filing_method_id === this.filingMethodSelection[name].id))
          })

          return filingMethods
        }
        else if (this.existingSavedItems) {
          return this
            .existingSavedItems
            .find(i => i.filing_method_id === this.filingMethodSelection[jurisdiction].id)
        }
        return filingMethods
      },

      domesticRegistrationChanged(oldHomeState, newHomeState) {
        if (this.product.filing_name === 'form a company' && oldHomeState) {
          this.removeByJurisdiction(oldHomeState)
        } else if (this.product.filing_name === 'register a company') {
          this.removeByJurisdiction(newHomeState)
        }
      },

      getLocalJurisdictions(jurisdiction) {
        if (!this.isLocal[jurisdiction]) {
          let arrayHolder = []
          const stateFilingMethods = this.product.filing_methods.filter(fm => fm.jurisdiction === jurisdiction)
          const localJurs = sortBy(uniq(stateFilingMethods.map(fm => fm.local_jurisdiction))).filter(name => name !== undefined)

          if (localJurs.length) {
            this.isLocal[jurisdiction] = localJurs

            localJurs.forEach(name => {
              const result = { name: name, state: jurisdiction }
              arrayHolder.push(result)
            })

            const group = {
              state: jurisdiction,
              local: arrayHolder,
            }

            this.localOptions.push(group)
          }
        }
      },

      filingMethodsForJurisdiction(jurisdiction) {
        return sortBy(
          this.product.filing_methods.filter(fm => fm.jurisdiction === jurisdiction && fm.purchasable === true),
          'cost'
        )
      },
      filingJurisdictionSelected(jurisdiction) {
        const methods = this.filingMethodsForJurisdiction(jurisdiction)

        if (this.isFilingDba) {
          this.getLocalJurisdictions(jurisdiction)
          const hasLocalJurisdictions = this.localOptions.find(item => item.state === jurisdiction)
          if (!hasLocalJurisdictions) {
            this.dbaAddOnCosts(methods[0], jurisdiction)
          }
        }

        if (methods.length === 1) {
          this.filingMethodSelection[jurisdiction] = methods[0]
        }

        if (!this.isMultiJurisdiction) {
          Object.keys(this.filingMethodSelection).forEach(key => {
            if (key !== jurisdiction) {
              this.removeByJurisdiction(key)
            }
          })
        }
      },

      filingMethodsForLocalJurisdiction(jurisdiction) {
        return sortBy(
          this.product.filing_methods.filter(fm => fm.local_jurisdiction === jurisdiction.name && fm.purchasable === true),
          'cost'
        )
      },
      filingLocalJurisdictionSelected(jurisdiction) {
        const methods = this.filingMethodsForLocalJurisdiction(jurisdiction)
        if (this.isFilingDba) {
          this.dbaAddOnCosts(methods[0], jurisdiction.name)
        }

        if (methods.length === 1) {
          this.filingMethodSelection[jurisdiction.name] = methods[0]
        }
      },
      async changeHomeStateHandler() {
        if (this.cartItems.length > 0) {
          if (confirm("If you change your home state, your current cart items will be cleared. Continue?")) {
            await this.clearCart()
          } else {
            return false
          }
        }
        this.presentCompanyDomesticRegistrationModal()
      },
      presentCompanyDomesticRegistrationModal() {
        this.$refs['company-domestic-registration-modal'].show()
      },
      updateFilingMethodsForJurisdiction(jurisdiction) {
        this.iJurisdictionSelection = [jurisdiction]

        this.filingJurisdictionSelected(jurisdiction)
      },
      ineligibleToOrderProduct(product) {
        this.ineligibleToOrderText = `You already have the ${ product.name } product on your account!`
        if (product.filing_name === 'tax id') return !this.eligibleToOrderEin
        else if (product.filing_name === 's corp') {
          if (this.requiresFormationFiling) {
            this.ineligibleToOrderText = `You must have a Form a Company order for this company to order ${product.name}!`
            return this.requiresFormationFiling && !this.formationProductInCart
          } return !this.eligibleToOrderSCorp
        } else if (this.isFormationFiling(product.filing_name)) {
          this.ineligibleToOrderText = 'You already have a Formation product on your account!'
          return !!this.formationFiling || false
        }
      },
      closeModal(){
        this.$emit('closeModal')
      },
    },
  }
</script>

<style lang="scss" scoped>
.ineligible-product{
  height: 5.6em
}
.ineligible-product-inner{
  margin-bottom: 1.563em;
}

.lower-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  text-align: center;
  margin-top: 0;
  width: 100%;

  div {
    display: inline-block;
  }
  .lower-container-text {
    text-align: right;
  }
  .lower-container-btn {
    margin-left: 1em;
    vertical-align: middle;
    justify-self: end;
  }
}

.lower-container-single-item {
  grid-template-columns: 1fr;
}
.jurisdiction-disclaimer {
  white-space: pre-line;
}

@media only screen and (max-width: 768px) {
  .lower-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.8em;

    .lower-container-text {
      margin: auto;
      text-align: left;
    }
  }
}
</style>
