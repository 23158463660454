export const dbaJurisdictionDisclaimers = {
  Florida: 'With the filing of a Fictitious Name (DBA) in Florida, the state form asks: "Florida County of Principal Place of Business." ' +
    'The form defaults to \'Multiple\', and due to this we will also default to Pinellas County when submitting the required Publication. ' +
    'Please see Chapter 50, Florida Statutes if you have any questions.',
  California: 'BPC § 17915: A fictitious business name statement shall be filed with the clerk of the county in which the registrant has his ' +
    'or her principal place of business in this state or, if the registrant has no place of business in this state, with the Clerk of Sacramento County.',
  Massachusetts: 'Physical address of the entity is required. Please note, per Title XV, Chapter 110, Sec. 5 additional filings may be needed ' +
    'if entity is situated in additional towns, cities, or counties.',
  Wyoming: 'Please note: the signed, dated, notarized application will be available on Public State Record once your Trade Name is filed.',
  Connecticut: 'Please note: Your business address for your DBA needs to be a physical address in the city you’re selecting, along with needing a ' +
    'specific business purpose as we cannot list ‘generic’.',
  'New Mexico': 'New Mexico requires  DBA filings have a corporate ending to specify the entity type. If this is not added when you fill in your DBA name, our ' +
    'team will add this in prior to submitting to state with the entity ending to match your company.',
}
